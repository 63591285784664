<template>
  <div>
    <div class="content">
      <div class="contentTop contentTopOne">
        <div class="contentTopTitle">角色列表</div>
        <div class="contentTopRight">
          <!-- <el-tooltip class="item" effect="dark" content="新增" placement="top">
            <img src="@/assets/images/setting/add.png" alt="" @click="addHandler">
          </el-tooltip> -->
          <el-input v-premiSub="'角色管理_搜索'" placeholder="角色名称" suffix-icon="el-icon-search" size="small" v-model="input2" @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%">
        <!-- <el-table-column type="selection" width="55">
        </el-table-column> -->
        <el-table-column prop="showId" label="序号" fixed="left" width="80">
        </el-table-column>
        <el-table-column prop="roleName" label="角色名称">
        </el-table-column>
        <el-table-column prop="roleId" label="角色编号">
        </el-table-column>
        <el-table-column prop="roleDesc" label="角色描述">
        </el-table-column>
        <el-table-column prop="mark" label="角色成员">
        </el-table-column>
        <el-table-column prop="modifyUser" label="修改人">
        </el-table-column>
        <el-table-column prop="modifyTime" label="修改时间">
        </el-table-column>
        <el-table-column prop="roleId" label="操作">
          <template slot-scope="scope">
            <el-tooltip v-premiSub="'角色管理_编辑'" class="item" v-if="scope.row.roleId !== '1'" effect="dark" content="编辑" placement="top">
              <img src="@/assets/images/plan/analysis/edit.png" alt="" @click="editFn(scope.row)">
            </el-tooltip>
            <el-tooltip class="item" v-else effect="dark" content="无法编辑" placement="top">
              <img src="@/assets/images/plan/analysis/editFalse.png" alt="">
            </el-tooltip>
            <!-- V2.0需求先隐藏 -->
            <!-- <el-tooltip v-premiSub="'角色管理_删除'" class="item" effect="dark" content="删除" placement="top">
              <img src="@/assets/images/plan/analysis/delete.png" alt="" @click="deleteFn(scope.row.id)">
            </el-tooltip> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination>
        </span>
      </div>
    </div>
    <!-- <popup :dialogVisible="dialogVisible" @copy="copy" @close="close" :mark="mark" @confirm="confirm" :link="link"
      @newAddUser="newAddUser" /> -->
    <ConfirmDel :dialogVisible="visible" @close="closeHandler" @ok="ok" />
    <edit :dialogVisible="editVisible" :roleDesc="roleDesc" :roleName="roleName" @editClose="editClose" @editOk="editOk"
      :roleInfo="roleInfo" :roleArr="roleArr" />
  </div>
</template>

<script>
// import popup from '../components/popup'
import ConfirmDel from '@/components/ConfirmDel.vue'
import edit from '../components/edit'
import { getApsRole, searchApsRole } from '@/api/setting.js'
export default {
  name: "role",
  components: {
    // popup,
    ConfirmDel,
    edit
  },
  data() {
    return {
      editVisible: false,
      roleName: '',
      roleDesc: '',
      type: true,
      status: false,
      tableData: [],
      currentPage1: 1,
      total: 0,  //总条数，用于分组组件
      input2: '',
      section: [
        '启用',
        '禁用'
      ],
      tempData: [],
      pageChange: 10,   //当前页面条数
      tempTotal: 0,  //总条数
      searchStatus: false,
      searchData: [],
      dialogVisible: false,
      visible: false,
      mark: '',
      link: '',
      roleNumber: 'A234535345435346',
      role: '管理员',
      desc: '负责系统维护',
      roleInfo: null,
      roleArr: []
    }
  },

  methods: {
    // // 新建角色--确认
    // newAddUser(val) {
    //   this.dialogVisible = false
    //   this.$message.success('新建角色成功！')
    // },
    editFn(val) {
      this.editVisible = true
      this.roleName = val.roleName
      this.roleDesc = val.roleDesc
      this.roleInfo = val
      this.roleArr = []
      if (val.userInfos) {
        val.userInfos.forEach(v => {
          this.roleArr.push(v.username)
        })
      }
    },
    deleteFn() {
      this.visible = true
    },
    // addHandler() {
    //   this.dialogVisible = true
    //   this.mark = 'newAddUser'
    // },
    copy() {
      this.dialogVisible = false
    },
    confirm(val) {
      this.dialogVisible = false
      // 用户状态
      console.log('当前打印:', val)
    },
    close() {
      this.dialogVisible = false
    },
    closeHandler() {
      this.visible = false
    },
    ok() {
      this.visible = false
      console.log('当前打印:已点击删除')
    },
    editClose() {
      this.editVisible = false
      this.roleArr = []
    },
    editOk() {
      this.editVisible = false
      this.renovate()
    },
    // 组件一页显示多少条数据
    async handleSizeChange(val) {
      this.pageChange = val
      if (this.searchStatus) {
        this.tempData = this.searchData.filter((v, i) => i < val)
      } else {
        const res = await getApsRole(1, val)
        if (res.code === 0) {
          const { records } = res.data
          this.tableData = records
          this.tempData = this.tableData
        }
      }
      this.currentPage1 = 1
    },
    // // 选择页数
    async handleCurrentChange(val) {
      if (this.searchStatus) {
        this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else {
        const res = await getApsRole(val, this.pageChange)
        if (res.code === 0) {
          const { records } = res.data
          this.tempData = this.tableData = records
        }
      }
      this.currentPage1 = val
    },
    async searchHandler() {
      const res = await searchApsRole(1, this.tempTotal, this.input2)
      // 搜索后的总条数
      this.searchData = res.data.records
      this.searchStatus = true
      this.pageChange = 10
      this.currentPage1 = 1
      this.tempData = this.searchData.filter((v, i) => i < 10)
      this.total = this.searchData.length
    },
    async renovate() {
      const res = await getApsRole(1, 10)
      const { records, total } = res.data
      this.tableData = this.tempData = records
      this.total = this.tempTotal = total
      this.pageChange = 10
      this.currentPage1 = 1
      this.status = this.searchStatus = false
      this.input2 = ''
      for (let i = 0; i < this.tempData.length; i++) {
        let mark = []
        if (Array.isArray(this.tempData[i].userInfos)) {
          this.tempData[i].userInfos.forEach(v => {
            mark.push(v.username)
          })
          this.tempData[i].mark = mark
          mark = mark.join('，')
          this.$set(this.tempData[i], 'mark', mark)
        }
      }
    }
  },
  created() {
    this.renovate()
  }
}
</script>

<style lang="sass" scoped>
@import '../user/style/style.scss'
</style>
